<template>
  <div class="userTablePage">
    <MyHeader
      :sup_this="sup_this"
      :query="query"
      :Files="Files"
      @headerChange="headerChange"
      :activeName="activeName"
    ></MyHeader>
    <CommonTable
      height="auto"
      :selection="false"
      :cols="userTableCols"
      :infoData="tableData"
      @sort-change="sort_change"
    >
      <template #mainUser="{ scoped: { row } }">
        {{ row.mainUser }}<span v-if="row.userStatus === 2" style="color:red">(已注销)</span>
      </template>
      <template #operationSlot="{ scoped: { row } }">
        <checkBtn :query="query" :rowData="row" v-if="checkPermission(['externaladmin:statistics:user:check'])"></checkBtn>
      </template>
    </CommonTable>
  </div>
</template>

<script>
import MyHeader from '../MyHeader'
import checkBtn from './checkBtn'
import { userFiles as Files } from '../MyHeader/formCols'
import { userTableCols } from './userTableCols'
export default {
  components: {
    MyHeader,
    checkBtn
  },
  props: {
    sup_this: {
      type: Object,
      required: true
    },
    query: {
      type: Object,
      required: true
    },
    tableData: {
      type: Array,
      required: true
    },
    activeName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      Files,
      userTableCols,
      orderItems: [{ column: '', asc: '' }],
      isSort: true
    }
  },

  methods: {
    headerChange(val) {
      for (let key in val) {
        if (key === 'mainUser') {
          this.$set(this.query, 'createByName', val[key])
          this.$nextTick(() => {
            this.sup_this.init()
          })
        } else {
          this.$set(this.query, key, val[key])
        }
      }
      // this.sup_this.init()
    },
    sort_change({ order, prop }) {
      const newProp = this.humpToUnderline(prop)
      this.orderItems[0].column = newProp
      if (order === 'ascending') {
        this.orderItems[0].asc = true
      } else if (order === 'descending') {
        this.orderItems[0].asc = false
      } else {
        this.isSort = false
      }
      if (this.isSort) {
        this.query['orderItems'] = this.orderItems
      } else {
        this.$delete(this.query, 'orderItems')
      }
      this.isSort = true
      this.sup_this.page = 1
      this.sup_this.init()
    },
    // 驼峰转下划线
    humpToUnderline(str) {
      return str.replace(/([A-Z])/g, '_$1').toLowerCase()
    }
  }
}
</script>

<style scoped lang="scss"></style>
